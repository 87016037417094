import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { requestOptionsGet } from "./requestOptionHeaders";
import { baseURL } from "./BaseUrl";

function ApproveInvoiceModel(props) {
  const [status, setStatus] = useState("");
  const [remarks, setRemarks] = useState("");

  const [approveRejectData, setApproveRejectData] = useState([]);

  console.log("VHID:", props.vhdId);

  useEffect(() => {
    fetchApprovalInvoice();
    setStatus("");
  }, [props.vhdId, props.uId]);

  const fetchApprovalInvoice = () => {
    let requestOption = requestOptionsGet;
    fetch(
      `${baseURL}/invoice_savejson/fetch_data_with_doc_entry_and_id/?fk_po_doc_entry=${props.vhdId}&id=${props.uId}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        console.log("APPROVAL-REJECT-DTA:", res);
        setApproveRejectData(res[0]);
      })
      .catch((error) => console.error("ERROR:", error));
  };

  const updateInvoiceStatus = () => {
    const invoiceStatusData = {
      invoice_save_id: props.invSaveId,
      dept_status: status,
      remarks: remarks,
      id: props.uId,
    };
    // console.log("REMARK::", remarks);
    let requestoptionPost = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(invoiceStatusData),
    };
    fetch(`${baseURL}/invoice_save_updatejson/changeStatus/`, requestoptionPost)
      .then((res) => res.json())
      .then((res) => {
        console.log("APPROVAL-STATUS:", res);
        setStatus("");
      })
      .catch((error) => console.error("ERROR:", error));
    setRemarks("");
    props.handleClose();
    props.fetchinvoicedata();
  };

  return (
    <div>
      <Modal
        show={props.aproveStatus}
        onHide={props.handleClose}
        className="main_model"
      >
        <div className="update-leavestatus-heading">
          <h6 className="update-leavesta-head">UPDATE STATUS</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <div className="container model-update-leavestatus-body">
          <form>
            <div className="row update-leave-status">
              <div className="col-md-12">
                <div className="select-status mb-2 d-flex">
                  <span className="col-md-3">
                    <div className="input-label-txt">Status</div>
                  </span>
                  <span className="col-md-9">
                    <Form.Select
                      aria-label="Default select example"
                      name="select-role"
                      className="form-control select-leave-update-status"
                      placeholder="Select Status"
                      onChange={(event) => setStatus(event.target.value)}
                    >
                      <option className="option-label" value={""}>
                        Select Status
                      </option>
                      <option>Approved</option>
                      <option>Rejected</option>
                    </Form.Select>
                  </span>
                </div>
                {status === "Rejected" ? (
                  <div className="col-md-12">
                    <div className="remarks-field d-flex">
                      <span className="col-md-3">
                        <h6 className="remark-text">Remarks</h6>
                      </span>
                      <span className="col-md-9">
                        <textarea
                          rows="3"
                          cols="20"
                          name="usrtxt"
                          wrap="hard"
                          className="form-control form remark-input"
                          id="remarks-update-invoice"
                          placeholder="Remarks for Rejection"
                          onChange={(e) => setRemarks(e.target.value)}
                        />
                      </span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </form>
        </div>
        <div className="btns-save-cancel">
          <div className="lead-save-button">
            <button
              onClick={updateInvoiceStatus}
              type="button"
              className="btn btn-save-up"
              data-bs-dismiss="modal"
            >
              <span>Save</span>
            </button>
          </div>
          <div className="lead-cancel-button">
            <button
              onClick={props.handleClose}
              type="button"
              className="btn btn-cancel"
              data-bs-dismiss="modal"
            >
              <span>Cancel</span>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ApproveInvoiceModel;
